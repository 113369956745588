import { FormProvider, useForm } from 'react-hook-form';
import { Form, FormGroup } from '@alicorpdigital/dali-components-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@insuma/mpp-ui/components/button';
import { defaultCustomization } from 'core/constants/customization.constants';
import { customizationRoutePath } from 'core/constants/routes.constants';
import { useDistributorsOptions, usePriceList, usePricesGroup, useZones } from 'core/hooks/master-entities';
import type { ICustomizationFormData } from 'core/model/interfaces/customization';
import { AutocompleteForm } from 'shared/components/forms';
import { Link } from 'shared/components/link';
import { schema } from './customization-form.schema';

import './customization-form.scss';

interface ICustomizationFormProps {
  onSubmit(data: ICustomizationFormData): void;
  formData?: ICustomizationFormData;
  isSaving?: boolean;
}

export const CustomizationForm = ({ isSaving = false, formData, onSubmit }: ICustomizationFormProps) => {
  const defaultValues: ICustomizationFormData = structuredClone(formData || defaultCustomization);

  const formMethods = useForm<ICustomizationFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    register,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;

  const selectedDexPrices = watch('conditions.externalPriceGroups');

  const { pricesGroupList, loading: loadingPriceGroup } = usePricesGroup();
  const { priceList, loading: loadingPriceList } = usePriceList(selectedDexPrices);
  const { distributorsList, loading: loadingDistributors } = useDistributorsOptions();
  const { externalZoneList, loading: loadingZones } = useZones();

  return (
    <div className="customization-form">
      <Link icon="caret-left" to={customizationRoutePath}>
        Atrás
      </Link>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="customization-form__title">{formData ? 'Editar' : 'Nueva'} personalización</h2>
        <FormProvider {...formMethods}>
          <div>
            <div className="row-container">
              <h4 className="customization-form__subtitle">1. Datos de la personalización</h4>
              <div className="customization-form__section">
                <FormGroup>
                  <Form.Label>Nombre de la personalización</Form.Label>
                  <Form.Input {...register('name')} placeholder="Ejemplo: Para apudex" />
                  {errors.name && <Form.HelperText isInvalid>{errors.name.message}</Form.HelperText>}
                </FormGroup>
              </div>
            </div>
            <br />
            <div className="row-container">
              <h4 className="customization-form__subtitle">2. Estructura de la personalización</h4>
              <p>Segmento de usuarios que podrán visualizar la página</p>
              <div>
                <Form.Group>
                  <Form.Label>Por grupo de precios DEX</Form.Label>
                  <div className="conditions__autocomplete">
                    <AutocompleteForm
                      source={pricesGroupList}
                      name="conditions.externalPriceGroups"
                      isLoading={loadingPriceGroup}
                      getOptionValue={option => option.priceGroupCode}
                      getOptionLabel={option => option.priceGroupDescription}
                      onChangeProp={() => {
                        setValue('conditions.externalPriceLists', []);
                        trigger('conditions');
                      }}
                      isMulti
                    />
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Por lista de precios DEX</Form.Label>
                  <div className="conditions__autocomplete">
                    <AutocompleteForm
                      source={priceList}
                      name="conditions.externalPriceLists"
                      isLoading={loadingPriceList}
                      getOptionValue={option => option.priceListCode}
                      getOptionLabel={option => option.priceListDescription}
                      onChangeProp={() => trigger('conditions')}
                      isMulti
                    />
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Por operador</Form.Label>
                  <div className="conditions__autocomplete">
                    <AutocompleteForm
                      source={distributorsList}
                      name="conditions.externalDistributors"
                      isLoading={loadingDistributors}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.name}
                      onChangeProp={() => trigger('conditions')}
                      isMulti
                    />
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Por oficina</Form.Label>
                  <div className="conditions__autocomplete">
                    <AutocompleteForm
                      source={externalZoneList}
                      name="conditions.externalZones"
                      isLoading={loadingZones}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.description}
                      onChangeProp={() => trigger('conditions')}
                      isMulti
                    />
                  </div>
                </Form.Group>

                <FormGroup>
                  <Form.Label>Por cliente</Form.Label>
                  <Form.Textarea
                    {...register('conditions.externalCustomers', {
                      deps: 'conditions',
                    })}
                  />
                </FormGroup>

                <Form.Group>
                  {errors.conditions && <Form.HelperText isInvalid>{errors.conditions.message}</Form.HelperText>}
                </Form.Group>
              </div>
            </div>
            <br />
            <div className="row-container">
              <h4 className="customization-form__subtitle">3. Excluir página de inicio</h4>
              <p>Segmento de usuarios que no podrán visualizar la página</p>
              <div className="customization-form__section">
                <FormGroup>
                  <Form.Textarea {...register('externalExcludedCustomers')} placeholder="Ingresar clientes" />
                </FormGroup>
              </div>
            </div>
          </div>
        </FormProvider>
        <div className="customization-form__actions">
          <Button size="md" type="submit" isLoading={isSaving} width="15rem">
            Guardar
          </Button>
        </div>
      </form>
    </div>
  );
};
