import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@insuma/mpp-ui/components/button';
import { useCSS } from '@insuma/mpp-ui/hooks';
import type { ISuggestion } from 'core/model/interfaces/suggested-product';
import { apiService } from 'core/services';
import { downloadFileFromUrl } from 'shared/utils/download-file.utils';
import { SuggestionError } from './components/suggestion-error/suggestion-error';
import { ImportCSV, SuggestionDetails } from './components';
import { schema } from './schema';

import './suggested-product.scss';

interface ISuggestedProductFormProps {
  onSubmit(data: ISuggestion): void;
  remoteData?: ISuggestion;
  isSaving: boolean;
  setIsReload(state: boolean): void;
}

export const SuggestedProductForm = ({ onSubmit, remoteData, isSaving, setIsReload }: ISuggestedProductFormProps) => {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const css = useCSS('suggested-product-form');
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: remoteData,
  });
  const {
    handleSubmit,
    watch,
    formState: { errors },
  } = formMethods;

  const internalPageUnforgivablesState = watch(`internalPage.unforgivables.state`);
  const internalPageEnhancersState = watch(`internalPage.enhancers.state`);
  const internalPageIdealSelectionState = watch(`internalPage.idealSelection.state`);
  const shoppingCartState = watch(`shoppingCart.state`);
  const homepageState = watch(`homepage.state`);
  const fixedBannerState = watch(`fixedBanner.state`);

  const toggleImportModal = () => setIsImportModalOpen(!isImportModalOpen);
  const handleExport = async () => {
    if (!remoteData?.exportUrl) throw new Error('exportUrl is required');
    const urls = await apiService.g2mFunctions.getDownloadUrls([remoteData.exportUrl]);
    if (urls) downloadFileFromUrl(urls[0]);
  };

  return (
    <FormProvider {...formMethods}>
      <ImportCSV
        setIsImportModalOpen={setIsImportModalOpen}
        isImportModalOpen={isImportModalOpen}
        toggleImportModal={toggleImportModal}
        setIsReload={setIsReload}
        templateUrl={remoteData?.templateUrl}
      />
      <div className={css()}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className={css('title')}>Productos Sugeridos</h2>
          <div className={css('actions--update-csv')}>
            <Button size="sm" variant="tertiary" hasIcon="left" iconName="upload" onClick={toggleImportModal}>
              Actualizar CSV
            </Button>
            <Button
              disabled={!remoteData?.exportUrl}
              size="sm"
              variant="tertiary"
              hasIcon="left"
              iconName="download"
              onClick={handleExport}
            >
              Exportación masiva
            </Button>
          </div>
          <h4 className={css('subtitle')}>Página interna</h4>
          <div className={css('card')}>
            <h4 className={css('subtitle')}>1. Configuración de la página interna</h4>
            <SuggestionDetails
              titleLabel="Imperdonables"
              name="internalPage"
              sublevelName="unforgivables"
              watchedValue={internalPageUnforgivablesState}
              titlePlaceholder="Título para Imperdonables"
            />
            <SuggestionDetails
              titleLabel="Potenciadores"
              name="internalPage"
              sublevelName="enhancers"
              watchedValue={internalPageEnhancersState}
              titlePlaceholder="Título para Potenciadores"
            />
            <SuggestionDetails
              titleLabel="Surtido Ideal"
              name="internalPage"
              sublevelName="idealSelection"
              watchedValue={internalPageIdealSelectionState}
              titlePlaceholder="Título para Surtido Ideal"
            />
            <SuggestionError errors={errors} field="atLeastOneType" name="internalPage" />
          </div>
          <h4 className={css('subtitle')}>Carrito de compra</h4>
          <div className={css('card')}>
            <h4 className={css('subtitle')}>1. Configuración en el carrito de compra</h4>
            <SuggestionDetails
              name="shoppingCart"
              watchedValue={shoppingCartState}
              titleLabel="Título de sugerencia"
              typesSelection
              stateTitle="3. Mostrar sugerencias"
            />
          </div>
          <h4 className={css('subtitle')}>Carrusel en el homepage</h4>
          <div className={css('card')}>
            <h4 className={css('subtitle')}>1. Configuración de sugerencias</h4>
            <SuggestionDetails
              name="homepage"
              watchedValue={homepageState}
              titleLabel="Título de sugerencia"
              typesSelection
              stateTitle="3. Mostrar sugerencias"
            />
          </div>
          <h4 className={css('subtitle')}>Banner fijo</h4>
          <div className={css('card')}>
            <h4 className={css('subtitle')}>1. Configuración del banner fijo</h4>
            <SuggestionDetails
              name="fixedBanner"
              watchedValue={fixedBannerState}
              titleLabel="Título de sugerencia"
              limitSuggestions={false}
            />
          </div>
          <div className={css('actions--save-form')}>
            <Button size="md" type="submit" disabled={isSaving}>
              Guardar
            </Button>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};
