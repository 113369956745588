import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Form, FormGroup } from '@alicorpdigital/dali-components-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@insuma/mpp-ui/components/button';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { customizationRoutePath } from 'core/constants/routes.constants';
import { ESuggestion, ESuggestionLabel } from 'core/model/enums/suggested-product.enum';
import type { ICustomizationSuggestedProductFormData } from 'core/model/interfaces/customization';
import { Link } from 'shared/components/link';
import { suggestedProductFormSchema } from './suggested-product-form.schema';

import './suggested-product-form.scss';

interface ISuggestedProductFormProps {
  isSaving?: boolean;
  onSubmit: (data: ICustomizationSuggestedProductFormData) => void;
  formData: ICustomizationSuggestedProductFormData;
}

export const SuggestedProductForm = ({ isSaving, onSubmit, formData }: ISuggestedProductFormProps) => {
  const css = useCSS('customization-suggested-product-form');
  const { customizationId } = useParams();
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(suggestedProductFormSchema),
    defaultValues: formData,
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods;

  return (
    <div className={css()}>
      <div className={css('back')}>
        <Link icon="caret-left" to={`${customizationRoutePath}/${customizationId}/modulos`}>
          Atrás
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className={css('title')}>Producto Sugerido</h2>
        <br />

        <div>
          <h4 className={css('subtitle')}>Carrusel personalizado</h4>
          <div className="row-container">
            <h4 className={css('subtitle')}>1. Configuración de Sugerencias en el Homepage</h4>
            <div className={css('section')}>
              <FormGroup>
                <Form.Label>Título de sugerencia</Form.Label>
                <Form.Input {...register('title')} placeholder="Título de sugerencia" />
                {errors.title && <Form.HelperText isInvalid>{errors.title.message}</Form.HelperText>}
              </FormGroup>
              <FormGroup>
                <Form.Label>N° máximo de sugerencias</Form.Label>
                <Form.Input {...register('maxSuggestions')} placeholder="N° máximo de sugerencias" />
                {errors.maxSuggestions && <Form.HelperText isInvalid>{errors.maxSuggestions.message}</Form.HelperText>}
              </FormGroup>
            </div>
          </div>
          <br />
          <div className="row-container">
            <h4 className={css('subtitle')}>2. Elegir opción a mostrar</h4>
            <div>
              <Form.Group>
                <Form.Check
                  direction="vertical"
                  id={`type-${ESuggestion.UNFORGIVABLES}`}
                  type="radio"
                  value={ESuggestion.UNFORGIVABLES}
                  {...register(`type`)}
                >
                  {ESuggestionLabel.UNFORGIVABLES}
                </Form.Check>
                <Form.Check
                  direction="vertical"
                  id={`type-${ESuggestion.ENHANCERS}`}
                  type="radio"
                  value={ESuggestion.ENHANCERS}
                  {...register(`type`)}
                >
                  {ESuggestionLabel.ENHANCERS}
                </Form.Check>
                <Form.Check
                  direction="vertical"
                  id={`type-${ESuggestion.IDEAL_SELECTION}`}
                  type="radio"
                  value={ESuggestion.IDEAL_SELECTION}
                  {...register(`type`)}
                >
                  {ESuggestionLabel.IDEAL_SELECTION}
                </Form.Check>
              </Form.Group>
            </div>
          </div>
        </div>

        <div className={css('actions')}>
          <Button type="submit" isLoading={isSaving} width="15rem">
            Guardar
          </Button>
        </div>
      </form>
    </div>
  );
};
