import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, SearchBox } from '@alicorpdigital/dali-components-react';
import { Button } from '@insuma/mpp-ui/components/button';
import { Table } from '@insuma/mpp-ui/components/table';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { notification } from '@insuma/mpp-ui/notifications';
import { navigationTreeRoutePath, productsRoutePath } from 'core/constants/routes.constants';
import type { INavigationTreeListItem } from 'core/model/interfaces/navigation';
import { apiService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'core/store';
import { fetchNavigationTrees, updateQuery } from 'core/store/navigation';
import { ConfirmationModal } from 'shared/components/confirmation-modal';
import { getErrorFromService } from 'shared/utils/https';
import { getTableColumns } from './navigation-tree-list.utils';

import './navigation-tree-list.scss';

interface INavigationTreeModalOptions {
  headerMessage: string;
  confirmationMessage: string | JSX.Element;
  onCancel: () => void;
  onConfirm: () => void;
}

export const NavigationTreeList = () => {
  const css = useCSS('navigation-tree-list');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { navigationTrees, query, pagination, isLoading } = useAppSelector(state => state.navigation);
  const [modalOptions, setModalOptions] = useState<INavigationTreeModalOptions | null>(null);

  const onDeleteConfirmation = async (id: number) => {
    try {
      await apiService.navigation.deleteNavigationTree(id);
      dispatch(fetchNavigationTrees({ query, page: pagination.currentPage }));
      notification.success('Árbol eliminado exitosamente');
    } catch (error) {
      notification.error(getErrorFromService(error));
    }
  };

  const onDuplicateConfirmation = async (id: number) => {
    try {
      const { treeId: createdTreeId } = await apiService.navigation.duplicateNavigationTree(id);
      navigate(`${productsRoutePath}${navigationTreeRoutePath}/editar/${createdTreeId}`);
      notification.success('Árbol duplicado con éxito');
    } catch (error) {
      notification.error('No se pudo duplicar el árbol');
    }
  };

  const onDeleteNavigationTreeClick = (item: INavigationTreeListItem) => {
    setModalOptions({
      headerMessage: 'Eliminar Árbol',
      confirmationMessage: (
        <span>
          ¿Estás seguro de eliminar el árbol <b>{item.name}</b>?
        </span>
      ),
      onCancel: () => setModalOptions(null),
      onConfirm: () => {
        onDeleteConfirmation(item.treeId);
        setModalOptions(null);
      },
    });
  };
  const onNewNavigationTreeClick = () => navigate(`${productsRoutePath}${navigationTreeRoutePath}/crear`);
  const onDuplicateNavigationTreeClick = async (item: INavigationTreeListItem) => {
    setModalOptions({
      headerMessage: 'Duplicar Árbol',
      confirmationMessage: (
        <span>
          ¿Estás seguro que deseas duplicar el árbol <b>{item.name}</b>?
        </span>
      ),
      onCancel: () => setModalOptions(null),
      onConfirm: () => {
        onDuplicateConfirmation(item.treeId);
        setModalOptions(null);
      },
    });
  };
  const onViewNavigationTreeClick = (id: number) => navigate(`${productsRoutePath}${navigationTreeRoutePath}/${id}`);
  const onEditNavigationTreeClick = (id: number) =>
    navigate(`${productsRoutePath}${navigationTreeRoutePath}/editar/${id}`);

  const handleSearchEnterEvent: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    dispatch(fetchNavigationTrees({ query, page: 1 }));
  };

  const handleQueryClear = () => {
    dispatch(updateQuery(''));
    dispatch(
      fetchNavigationTrees({
        query: '',
        page: 1,
      }),
    );
  };

  const handlePageChange = (page: number) => {
    dispatch(
      fetchNavigationTrees({
        query,
        page,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      fetchNavigationTrees({
        query,
        page: pagination.currentPage,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const columns = getTableColumns({
    onViewNavigationTreeClick,
    onEditNavigationTreeClick,
    onDeleteNavigationTreeClick,
    onDuplicateNavigationTreeClick,
  });

  return (
    <div className={css()}>
      {modalOptions && (
        <ConfirmationModal
          head={modalOptions.headerMessage}
          message={modalOptions.confirmationMessage}
          onConfirm={modalOptions.onConfirm}
          onCancel={modalOptions.onCancel}
        />
      )}
      <h2 className={css('title')}>Árbol de navegación</h2>
      <div className={css('actions')}>
        <Form onSubmit={handleSearchEnterEvent} className={css('search')}>
          <SearchBox
            className={css('search-box')}
            size="sm"
            placeholder="Buscar por Nombre"
            value={query}
            onChange={e => dispatch(updateQuery(e.target.value))}
            onDeleteContent={handleQueryClear}
          />
          <Button size="sm" isIconOnly iconName="magnifying-glass" iconWeight="bold" variant="primary" type="submit" />
        </Form>
        <Button iconWeight="bold" size="sm" onClick={onNewNavigationTreeClick} hasIcon="left" iconName="plus">
          Nuevo Árbol
        </Button>
      </div>
      <div>
        <Table
          isLoading={isLoading}
          columns={columns}
          rows={navigationTrees}
          noDataMessage="No se encontraron resultados"
          paginationModel={{
            currentPage: pagination.currentPage - 1,
            onChangePage: handlePageChange,
            totalPages: pagination.totalPages,
            pageSize: pagination.itemsPerPage,
          }}
        />
      </div>
    </div>
  );
};
