import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, SearchBox } from '@alicorpdigital/dali-components-react';
import { Button } from '@insuma/mpp-ui/components/button';
import { Table } from '@insuma/mpp-ui/components/table';
import { notification } from '@insuma/mpp-ui/notifications';
import { itemsPerPage } from 'core/constants/pagination.constants';
import { customizationRoutePath } from 'core/constants/routes.constants';
import type { IDisplayCustomization } from 'core/model/interfaces/customization';
import { apiService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'core/store';
import { selectCustomization, updateFilters } from 'core/store/customization';
import { DeleteConfirmationModal } from '../components/delete-confirmation-modal';
import { getCustomizationColumns } from '../customization-utils';
import { useCustomizationList } from '../hooks/use-customization-list.hooks';

import './customization-list.scss';

export const CustomizationList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { filters } = useAppSelector(selectCustomization);
  const [query, setQuery] = useState<string>(filters.q);
  const [itemToDelete, setItemToDelete] = useState<IDisplayCustomization | null>(null);

  const { customizations, totalPages, isCustomizationLoading } = useCustomizationList(filters);

  const onViewClick = (id: number) => navigate(`${customizationRoutePath}/${id}/modulos`);
  const onEditClick = (id: number) => navigate(`${customizationRoutePath}/editar/${id}`);
  const onDeleteConfirmation = async (id: number) => {
    try {
      await apiService.customization.deleteCustomization(id);
      dispatch(updateFilters({ ...filters }));
      notification.success('La personalización ha sido eliminada');
    } catch (error) {
      notification.error('Error eliminando la personalización');
    }
  };

  const columns = getCustomizationColumns(onViewClick, onEditClick, item => setItemToDelete(item));

  const handleSearchEnterEvent: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    dispatch(
      updateFilters({
        page: 1,
        q: query,
      }),
    );
  };

  const handleQueryClear = () => {
    setQuery('');
    dispatch(
      updateFilters({
        page: 1,
        q: '',
      }),
    );
  };

  return (
    <div className="customization-list">
      <h2 className="customization-list__title">Personalización</h2>
      <div className="customization-list__actions">
        <Form onSubmit={handleSearchEnterEvent} className="customization-list__search">
          <SearchBox
            className="customization-list__search-box"
            size="sm"
            placeholder="Buscar por nombre, operador y lista de precios"
            value={query}
            onChange={e => setQuery(e.target.value)}
            onDeleteContent={handleQueryClear}
          />
          <Button size="sm" isIconOnly iconName="magnifying-glass" iconWeight="bold" variant="primary" type="submit" />
        </Form>
      </div>
      <div className="customization-list__actions">
        <span />
        <Button
          size="sm"
          type="button"
          iconName="plus"
          hasIcon="left"
          onClick={() => navigate(`${customizationRoutePath}/crear`)}
        >
          Nueva personalización
        </Button>
      </div>
      <div>
        <DeleteConfirmationModal
          selectedItem={itemToDelete}
          setSelectedItem={setItemToDelete}
          onDeleteConfirmation={onDeleteConfirmation}
        />
        <Table
          isLoading={isCustomizationLoading}
          columns={columns}
          rows={customizations}
          paginationModel={{
            currentPage: filters.page - 1,
            onChangePage: page =>
              dispatch(
                updateFilters({
                  page,
                  q: filters.q,
                }),
              ),
            totalPages,
            pageSize: itemsPerPage,
          }}
          noDataMessage="No se encontraron resultados"
        />
      </div>
    </div>
  );
};
