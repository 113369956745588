import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Form } from '@alicorpdigital/dali-components-react';
import { Button } from '@insuma/mpp-ui/components/button';
import { usePriceList, usePricesGroup, useZones } from 'core/hooks/master-entities';
import { EApplyToOption } from 'core/model/enums/distributor.enum';
import type { IDistributorForm } from 'core/model/interfaces/distributors';
import { useSalesForce } from 'screens/logistics/distributors/hooks/use-sales-force.hooks';
import { AutocompleteForm } from 'shared/components/forms';
import { getPriceGroupList } from './minimum-purchase-form.utils';

import './minimum-purchase-form.scss';

interface IPurchaseFormProps {
  onSaveClick: (index: number) => void;
  onCancelClick: (index: number) => void;
  index: number;
}

export const MinimumPurchaseForm = ({ onSaveClick, onCancelClick, index }: IPurchaseFormProps) => {
  const { branchCode, distributorCode } = useParams();
  const {
    register,
    trigger,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<IDistributorForm>();

  const selectedPriceGroupWatch = watch(`minimumPurchases.${index}.priceGroup`);
  const minimumPurchase = useWatch<IDistributorForm, 'minimumPurchases'>({
    control,
    name: 'minimumPurchases',
  })[index];

  const selectedPriceGroup = selectedPriceGroupWatch;
  const selectedSalesForces = minimumPurchase.externalSalesForce;
  const { pricesGroupList, loading: loadingPriceGroup } = usePricesGroup();
  const { externalZoneList, loading: loadingZones } = useZones();
  const selectedPriceGroupInPriceList = useMemo(
    () => selectedPriceGroup.map(selectedPriceGroupItem => selectedPriceGroupItem.priceGroupCode),
    [selectedPriceGroup],
  );
  const { priceList, loading: loadingPriceList } = usePriceList(selectedPriceGroupInPriceList);
  const { salesForceList, loading: loadingSalesForce } = useSalesForce(branchCode, distributorCode);

  const onSaveChanges = async () => {
    const hasentSquemaErrors = await trigger(`minimumPurchases.${index}`);

    if (hasentSquemaErrors) {
      onSaveClick(index);
    }
  };

  const priceGroupsToUse = getPriceGroupList(salesForceList, pricesGroupList, selectedSalesForces);

  return (
    <div className="minimum-purchase-form">
      <div className="minimum-purchase-form__row">
        <Form.Group className="minimum-purchase-form__row--full-width">
          <Form.Label>Por fuerza de venta</Form.Label>
          <AutocompleteForm
            source={salesForceList}
            name={`minimumPurchases.${index}.externalSalesForce`}
            isMulti={false}
            isLoading={loadingSalesForce}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.id}
            onChangeProp={() => {
              setValue(`minimumPurchases.${index}.priceGroup`, []);
              setValue(`minimumPurchases.${index}.customerPriceList`, []);
            }}
          />
        </Form.Group>
      </div>
      <div className="minimum-purchase-form__row">
        <div className="minimum-purchase-form__group">
          <Form.Group>
            <Form.Label>Por grupo de precios</Form.Label>

            <AutocompleteForm
              isObjectValue
              source={priceGroupsToUse}
              name={`minimumPurchases.${index}.priceGroup`}
              isLoading={loadingPriceGroup}
              isMulti
              getOptionValue={option => option.priceGroupCode}
              getOptionLabel={option => option.priceGroupDescription}
              onChangeProp={() => {
                setValue(`minimumPurchases.${index}.customerPriceList`, []);
              }}
            />
          </Form.Group>
        </div>
        <div className="minimum-purchase-form__group">
          <Form.Group>
            <Form.Label>Por lista de precios</Form.Label>
            <AutocompleteForm
              isObjectValue
              source={priceList}
              isMulti
              name={`minimumPurchases.${index}.customerPriceList`}
              isLoading={loadingPriceList}
              isDisabled={selectedPriceGroup.length === 0}
              getOptionValue={option => option.priceListCode}
              getOptionLabel={option => option.priceListDescription}
            />
          </Form.Group>
        </div>
      </div>
      <div className="minimum-purchase-form__row">
        <Form.Group className="minimum-purchase-form__row--full-width">
          <Form.Label>Por oficina</Form.Label>
          <AutocompleteForm
            isObjectValue
            source={externalZoneList}
            name={`minimumPurchases.${index}.zones`}
            isLoading={loadingZones}
            getOptionValue={option => option.id}
            getOptionLabel={option => `${option.id} - ${option.description}`}
            isMulti
          />
        </Form.Group>
      </div>
      <div className="minimum-purchase-form__row">
        <Form.Group className="minimum-purchase-form__row--full-width">
          <Form.Label>Por cliente</Form.Label>
          <Form.Textarea {...register(`minimumPurchases.${index}.customers`)} />

          {errors.minimumPurchases?.[index]?.customers && (
            <Form.HelperText isInvalid>{errors.minimumPurchases[index].customers.message}</Form.HelperText>
          )}
        </Form.Group>
      </div>

      <div className="minimum-purchase-form__row">
        <div className="minimum-purchase-form__group">
          {errors.minimumPurchases?.[index] && (
            <Form.HelperText isInvalid>{errors.minimumPurchases[index].message}</Form.HelperText>
          )}
        </div>
      </div>

      <div className="minimum-purchase-form__row">
        <div className="minimum-purchase-form__group">
          <Form.Check
            type="radio"
            value={EApplyToOption.CART}
            id={EApplyToOption.CART}
            label="Carrito"
            {...register(`minimumPurchases.${index}.applyTo`)}
          />
          <Form.Check
            type="radio"
            value={EApplyToOption.BRIEFCASE}
            id={EApplyToOption.BRIEFCASE}
            label="Portafolio"
            {...register(`minimumPurchases.${index}.applyTo`)}
          />
        </div>
      </div>

      {minimumPurchase?.applyTo === EApplyToOption.CART && (
        <div className="minimum-purchase-form__row">
          <div className="minimum-purchase-form__group">
            <Form.Group>
              <Form.Label>Al Carrito</Form.Label>
              <Form.Input type="number" {...register(`minimumPurchases.${index}.amount`)} placeholder="S/0" />
              {errors.minimumPurchases?.[index]?.amount && (
                <Form.HelperText isInvalid>{errors.minimumPurchases[index].amount.message}</Form.HelperText>
              )}
            </Form.Group>
          </div>
        </div>
      )}

      {minimumPurchase?.applyTo === EApplyToOption.BRIEFCASE && (
        <div className="minimum-purchase-form__row">
          <div className="minimum-purchase-form__group">
            <Form.Group>
              <Form.Label>Portafolio Alicorp</Form.Label>
              <Form.Input type="number" {...register(`minimumPurchases.${index}.amountAlicorp`)} placeholder="S/0" />
              {errors.minimumPurchases?.[index]?.amountAlicorp && (
                <Form.HelperText isInvalid>{errors.minimumPurchases[index].amountAlicorp.message}</Form.HelperText>
              )}
            </Form.Group>
          </div>
          <div className="minimum-purchase-form__group">
            <Form.Group>
              <Form.Label>Portafolio Aliados</Form.Label>
              <Form.Input type="number" {...register(`minimumPurchases.${index}.amountAllied`)} placeholder="S/0" />
              {errors.minimumPurchases?.[index]?.amountAllied && (
                <Form.HelperText isInvalid>{errors.minimumPurchases[index].amountAllied.message}</Form.HelperText>
              )}
            </Form.Group>
          </div>
        </div>
      )}

      <div className="minimum-purchase-form__actions">
        <Button size="lg" variant="secondary" onClick={onSaveChanges} type="button">
          Aplicar
        </Button>
        <Button size="lg" variant="tertiary" onClick={() => onCancelClick(index)} type="button">
          Cancelar
        </Button>
      </div>
    </div>
  );
};
