import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@insuma/mpp-ui/components/button';
import { Icon } from '@insuma/mpp-ui/components/icon';
import { DEFAULT_DISTRIBUTOR_DETAIL } from 'core/constants/logistics.constants';
import { distributorsRoutePath, logisticsRoutePath } from 'core/constants/routes.constants';
import type { ICustomVisitDelivery, IDistributorForm } from 'core/model/interfaces/distributors';
import { Link } from 'shared/components/link';
import { schema } from './distributors-schema';
import { DistributorData, DistributorMinimumPurchase, DistributorMirrorDays, DistributorTax } from './steps';

import './distributor-form.scss';

interface IDistributorsFormProps {
  onSubmit(data: IDistributorForm): void;
  distributor: IDistributorForm;
  customVisitDelivery: Array<ICustomVisitDelivery>;
  isSaving: boolean;
}

export const DistributorsForm = ({ distributor, customVisitDelivery, isSaving, onSubmit }: IDistributorsFormProps) => {
  const defaultValues: IDistributorForm = {
    ...structuredClone(distributor || DEFAULT_DISTRIBUTOR_DETAIL),
  };

  const formMethods = useForm<IDistributorForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { handleSubmit } = formMethods;

  return (
    <div className="distributors-form">
      <div className="distributors-form__link-wrapper">
        <Link to={`${logisticsRoutePath}${distributorsRoutePath}`}>
          <Icon size="md" name="caret-left" />
          Atrás
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="distributors-form">
        <h2 className="distributors-form__title-leading">Operador</h2>

        <FormProvider {...formMethods}>
          <div className="distributors-form__steps">
            <DistributorData />
            <DistributorMirrorDays customVisitDeliveries={customVisitDelivery} />
            <DistributorMinimumPurchase />
            <DistributorTax />
          </div>
        </FormProvider>
        <div className="distributors-form__save">
          <Button size="md" type="submit" disabled={isSaving}>
            {isSaving ? 'Guardando...' : 'Guardar'}
          </Button>
        </div>
      </form>
    </div>
  );
};
