import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, SearchBox } from '@alicorpdigital/dali-components-react';
import { Button } from '@insuma/mpp-ui/components/button';
import type { IStaticPagesFilters } from 'core/model/interfaces/static-pages';
import { useAppDispatch } from 'core/store';
import { updateFilters } from 'core/store/static-pages';

interface IHeaderActions {
  filters: IStaticPagesFilters;
  createRoute: string;
}
export const HeaderActions = ({ filters, createRoute }: IHeaderActions) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState<string>(filters.title);

  const onNewStaticPageClick = () => navigate(createRoute);

  const handleSearchEnterEvent = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateFilters({ title: query }));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleQueryClear = () => {
    setQuery('');
    dispatch(updateFilters({ title: '' }));
  };

  return (
    <div className="static-pages-list__actions">
      <Form onSubmit={handleSearchEnterEvent} className="static-pages-list__search">
        <SearchBox
          className="static-pages-list__searchbox"
          size="sm"
          placeholder="Buscar por nombre"
          value={query}
          onChange={handleSearchChange}
          onDeleteContent={handleQueryClear}
        />
        <Button isIconOnly iconWeight="bold" iconName="magnifying-glass" size="sm" type="submit" />
      </Form>
      <Button iconWeight="bold" size="sm" onClick={onNewStaticPageClick} hasIcon="left" iconName="plus">
        Nueva página
      </Button>
    </div>
  );
};
