import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@insuma/mpp-ui/components/button';
import { notification } from '@insuma/mpp-ui/notifications';
import { defaultBannerList, defaultNewBanner } from 'core/constants/banners.constants';
import { AT_LEAST_ONE_EDITING_BANNER } from 'core/constants/error-messages.constants';
import { customizationRoutePath } from 'core/constants/routes.constants';
import type { IBannerForm, IBannerListForm } from 'core/model/interfaces/banners';
import { Link } from 'shared/components/link';
import { schema } from '../../customization-banners-form.schema';
import { BannerForm } from '../banner-form/banner-form';
import { BannerView } from '../banner-view/banner-view';

import './banner-list.scss';

interface IBannerListProps {
  customizationId: number;
  bannerList: IBannerListForm;
  isSaving: boolean;
  onSubmit(formData: IBannerListForm): void;
}

export const BannerList = ({ customizationId, bannerList, isSaving, onSubmit }: IBannerListProps) => {
  const [initialBannerState, setInitialBannerState] = useState<IBannerForm | null>(null);
  const formMethods = useForm<IBannerListForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: bannerList.banners?.length > 0 ? bannerList : defaultBannerList,
  });

  const { handleSubmit, control, watch } = formMethods;
  const { append, remove, update } = useFieldArray({
    control,
    name: 'banners',
  });

  const bannerItems = watch('banners');
  const isListEmpty = bannerItems.length === 0;
  const isThereEditingBanners = bannerItems.some(banner => banner.isEditing);

  const onSubmitForm = async (formData: IBannerListForm) => {
    if (!isThereEditingBanners) {
      onSubmit(formData);
    } else notification.error(AT_LEAST_ONE_EDITING_BANNER);
  };

  const handleAddBanner = () => {
    append(defaultNewBanner);
  };

  const handleSaveBanner = (index: number) => {
    const selectedBanner = bannerItems[index];
    update(index, { ...selectedBanner, isEditing: false, isNew: false });
  };

  const handleDeleteBanner = (index: number) => {
    remove(index);
    notification.success('Banner eliminado con éxito');
  };

  const handleToEditBanner = (index: number) => {
    const selectedBanner = bannerItems[index];

    setInitialBannerState(selectedBanner);
    update(index, { ...selectedBanner, isEditing: true });
  };

  const handleCancelBanner = (index: number) => {
    const selectedBanner = bannerItems[index];

    if (selectedBanner.isNew) remove(index);
    else if (initialBannerState) update(index, { ...initialBannerState, isEditing: false });

    setInitialBannerState(null);
  };

  return (
    <div className="banner-list">
      <Link icon="caret-left" to={`${customizationRoutePath}/${customizationId}/modulos`}>
        Atrás
      </Link>
      <h2 className="banner-list__title">Banners</h2>

      <form onSubmit={handleSubmit(onSubmitForm)}>
        <FormProvider {...formMethods}>
          <div className={`banner-list__list${isListEmpty ? '--no-elements' : ''}`}>
            {bannerItems.map((banner, index) => (
              <div key={`banner-${banner.id}-${index}`}>
                {banner.isEditing ? (
                  <BannerForm index={index} onSaveClick={handleSaveBanner} onCancelClick={handleCancelBanner} />
                ) : (
                  <BannerView
                    index={index}
                    onEditClick={handleToEditBanner}
                    onDeleteClick={handleDeleteBanner}
                    isActionsDisabled={isThereEditingBanners}
                  />
                )}
              </div>
            ))}
          </div>
        </FormProvider>

        <div className="banner-list__action-buttons">
          <div className="banner-list__add-banner-container">
            <Button
              type="button"
              disabled={isThereEditingBanners}
              onClick={handleAddBanner}
              hasIcon="left"
              iconName="plus"
              variant="tertiary"
            >
              Añadir banner
            </Button>
          </div>

          <div className="banner-list__save-container">
            <Button size="md" type="submit" width="15rem" isLoading={isSaving}>
              Guardar
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
